import { request } from '../request';
import { PaginatedResponse } from '@libs/models/common';

export function fetchShiftsList(page = 1, pageSize = 10, filter?: ShiftListRequestParams) {
  return request.get<ShiftListResponse>('/v2/shifts/list-view', {
    params: {
      pageNumber: page,
      pageSize,
      ...filter,
    },
  });
}

type ShiftListRequestParams = {
  startDate?: string;
  endDate?: string;
  externalLocationNames?: string[];
  qualificationIds?: string[];
  staffIds?: string[];
  statuses?: ShiftStatus[];
  specialityIds?: string[];
  facilityTenantIds?: string[];
  shiftTimeTypes?: ShiftType[];
};

type ShiftStatus =
  | 'Pending'
  | 'Assigned'
  | 'Reassigned'
  | 'NotAllowed'
  | 'Canceled'
  | 'InProgress'
  | 'Unfilled'
  | 'Completed';
type ShiftType = 'Eve' | 'Day' | 'Noc';

type ShiftListResponse = PaginatedResponse<
  {
    id: string;
    startDate: string;
    endDate: string;
    qualificationToPayFilters: {
      id: string;
      name: string;
      abbreviation: string;
      category: string;
    }[];
    chosenQualifications:
      | {
          id: string;
          name: string;
          abbreviation: string;
          category: string;
        }[]
      | null;
    specialityToPay: {
      id: string;
      title: string;
      category: string;
    } | null;
    status: ShiftStatus;
    shiftTimeType: 'Eve' | 'Day' | 'Noc';
    note: string;
    staff: {
      id: string;
      firstName: string;
      lastName: string;
      imageUrl?: string;
    } | null;
    shiftSeriesId: string | null;
    shiftRotationId: string | null;
    location: {
      city: string;
      state: string;
      name: string;
      firstAddress: string;
      secondAddress: string;
    };
    facilityTenantId: string;
  }[]
>;
