import { create } from 'zustand';
import { ShiftHistoryLog } from '@libs/models/shifts';
import dayjs from 'dayjs';

type State = {
  log: ShiftHistoryLog[];
  loading: boolean;
  shiftId: string | null;
  loadLog: (shiftId: string) => void;
};

export const useShiftHistoryLog = create<State>()((set, get) => ({
  log: [],
  loading: false,
  shiftId: null,
  loadLog: async (newId: string) => {
    const { shiftId } = get();
    const sameId = shiftId === newId;
    // Suppress loading state if the shiftId is the same
    set({ loading: !sameId });
    if (!sameId) {
      // Clear notes if the shiftId is different
      set({ log: [] });
    }

    await new Promise((resolve) => setTimeout(resolve, 1000));

    set({
      log: [
        {
          id: '3',
          event: 'started',
          date: dayjs('Aug 17, 2024, 9:57 AM'),
          user: 'Hannah King',
          shiftDetails: {
            shiftLength: 28800000,
            shiftStart: dayjs('Aug 17, 2024, 9:00 AM'),
            assignee: {
              avatar: 'avatar',
              name: 'Nora Gebbia',
              assignedByClient: true,
              specialty: 'CHA',
            },
          },
        },
        {
          id: '3',
          event: 'assigned',
          date: dayjs('Jul 26, 2024, 10:28 AM'),
          user: 'Hannah King',
          shiftDetails: {
            shiftLength: 28800000,
            shiftStart: dayjs('Aug 17, 2024, 9:00 AM'),
            assignee: {
              avatar: 'avatar',
              name: 'Nora Gebbia',
              assignedByClient: true,
              specialty: 'CHA',
            },
          },
        },
        {
          id: '2',
          event: 'shared',
          date: dayjs('Jul 26, 2024, 10:28 AM'),
          user: 'Hannah King',
          shiftDetails: {
            shiftLength: 28800000,
            shiftStart: dayjs('Aug 17, 2024, 9:00 AM'),
          },
        },
        {
          id: '1',
          event: 'created',
          date: dayjs('Aug 17, 2024, 9:57 AM'),
          user: 'Hannah King',
          shiftDetails: {
            shiftLength: 28800000,
            shiftStart: dayjs('Aug 17, 2024, 9:00 AM'),
          },
        },
      ],
      loading: false,
      shiftId: newId,
    });
  },
}));
