import { AxiosError, isAxiosError } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseError(error: any) {
  if (!(error instanceof Error)) {
    return new Error('Something went wrong');
  }
  if (!isAxiosError(error) || !error.response) {
    return error;
  }

  if (!isServerError(error)) {
    return error;
  }

  if (typeof error.response.data === 'string') {
    return new Error(error.response.data);
  }

  const errorData = error.response.data;

  return new Error(errorData.detail ?? errorData.title);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isServerError(error: any): error is AxiosError<ServerErrorData, any> {
  if (!isAxiosError(error) || !error.response) {
    return false;
  }

  return !!error.response.data;
}

type ServerErrorData = {
  status: number;
  type: string;
  title: string;
  detail?: string;
  instance: string;
};
