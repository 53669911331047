import { Flex } from '@atoms/layout';
import { ComingSoonBanner } from './comingSoonBanner';

export function ComingSoonPage() {
  return (
    <Flex alignCenter justifyCenter height="100%" column textAlign="center">
      <ComingSoonBanner />
    </Flex>
  );
}
