import { Specialty } from '@libs/models/talents';
import { request } from '../request';

export function fetchSpecialities(params: GetSpecialitiesParams) {
  return request.get<SpecialitiesResponse>('/v2/settings/specialities', {
    params: {
      orderBy: 'name',
      ...params,
    },
  });
}

export type GetSpecialitiesParams = {
  page?: number;
  size?: number;
};

type SpecialitiesResponse = {
  data: Specialty[];
  total: number;
};

export async function getPredefinedSpecialties(): Promise<Specialty[]> {
  const response = await request.get<Specialty[]>('/v2/nurses/specialities');

  return response.data;
}

export async function updateOrgSpecialties(id: string, setting: Specialty): Promise<void> {
  return request.patch(`/v2/settings/specialities/${id}`, {
    title: setting.name,
    category: setting.category,
    nurseQualificationIds: setting.nurseQualifications.map((elem) => elem.id),
    otherQualificationIds: setting.otherQualifications.map((elem) => elem.id),
  });
}

export async function createOrgSpecialty(setting: Specialty): Promise<void> {
  return request.post('/v2/settings/specialities', {
    title: setting.name,
    category: setting.category,
    nurseQualificationIds: setting.nurseQualifications.map((elem) => elem.id),
    otherQualificationIds: setting.otherQualifications.map((elem) => elem.id),
  });
}
