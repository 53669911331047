import { forwardRef, ReactNode, useId } from 'react';
import FormControl from '@mui/material/FormControl';
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { FormLabel } from './formLabel';
import { SxProps, Theme, useTheme } from '@mui/material/styles';

export type SelectOption = { value: string; label: ReactNode; key: string; disabled?: boolean };
export type SelectOptions = SelectOption[];

export type SelectProps = {
  value?: string;
  fullWidth?: boolean;
  id?: string;
  labelId?: string;
  label?: ReactNode;
  onChange?: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
  options?: SelectOptions;
  helperText?: ReactNode;
  rounded?: boolean;
  size?: 'small' | 'medium';
  required?: boolean;
  noBorder?: boolean;
  disabled?: boolean;
  placeholder?: ReactNode;
  sx?: SxProps<Theme>;
  error?: boolean;
  autoFocus?: boolean;
  multiple?: boolean;
  readOnly?: boolean;
};

export const Select = forwardRef<HTMLSelectElement, SelectProps>(function Select(
  {
    value,
    fullWidth,
    id,
    labelId,
    label,
    onChange,
    options,
    helperText,
    rounded,
    size,
    required,
    noBorder,
    disabled,
    placeholder,
    sx,
    error,
    autoFocus,
    ...props
  },
  ref,
) {
  const theme = useTheme();
  const generatedId = useId();
  const generatedLabelId = useId();
  const internalId = id ?? generatedId;
  const internalLabelId = labelId ?? generatedLabelId;
  const roundedSize = size === 'small' ? '20px' : '28px';
  const borderStyles = noBorder ? { [`.${outlinedInputClasses.notchedOutline}`]: { borderStyle: 'none' } } : {};
  return (
    <FormControl fullWidth={fullWidth} error={!!error}>
      <label id={internalLabelId} htmlFor={internalId}>
        <FormLabel disabled={disabled} required={required}>
          {label}
        </FormLabel>
      </label>
      <MuiSelect
        variant="outlined"
        labelId={internalLabelId}
        id={internalId}
        value={value}
        onChange={onChange}
        sx={{
          borderRadius: rounded ? roundedSize : undefined,
          ...borderStyles,
          ...(typeof sx === 'function' ? sx(theme) : sx),
        }}
        size={size}
        autoFocus={!!autoFocus}
        displayEmpty
        disabled={disabled}
        required={required}
        inputRef={ref}
        {...props}
        renderValue={(value) => {
          if (!value) {
            return <Typography color="text.secondary">{placeholder}</Typography>;
          }
          return options?.find((option) => option.value === value)?.label;
        }}
      >
        {options?.map((option) => (
          <MenuItem key={option.key} value={option.value} disabled={option.disabled}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
});
