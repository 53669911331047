import { create } from 'zustand';
import { getOrganizationDetails, saveOrganizationDetails } from '@libs/api/settings';
import { OrganizationDetails } from '@libs/models/settings';

type InitialState = {
  loading: boolean;
  data?: OrganizationDetails;
  save: (settings: Omit<OrganizationDetails, 'id'>) => Promise<void>;
  load: () => void;
};

export const useOrganizationDetailsStore = create<InitialState>()((set) => ({
  loading: false,
  save: async (settings) => {
    set({ loading: true });
    if (!settings) return;
    try {
      await saveOrganizationDetails({
        address: {
          city: settings.city,
          firstAddress: settings.address1,
          secondAddress: settings.address2,
          state: settings.state,
          zipCode: settings.postal_code,
        },
        phoneNumber: settings.agency_phone_number,
        contact: {
          email: settings.contact_person_details.email,
          name: settings.contact_person_details.primary_contact_full_name,
          phoneNumber: settings.contact_person_details.primary_phone_number,
          secondaryPhoneNumber: settings.contact_person_details.secondary_number,
          title: settings.contact_person_details.job_title,
        },
        ein: settings.employer_identification_number,
        name: settings.name,
        websiteUrl: settings.website,
        linesOfBusiness: settings.lineOfBusiness,
        doingBusinessAs: settings.dba,
        registrationTime: settings.registration_date,
        workWeekDayStart: settings.working_week_start,
        organizationType: settings.type,
      });
    } finally {
      set({ loading: false });
    }
  },
  load: async () => {
    set({ loading: true });
    try {
      const { data } = await getOrganizationDetails();
      set({
        loading: false,
        data: {
          address1: data.address.firstAddress,
          address2: data.address.secondAddress,
          agency_phone_number: data.phoneNumber,
          city: data.address.city,
          contact_person_details: {
            email: data.contact.email,
            job_title: data.contact.title,
            primary_contact_full_name: data.contact.name,
            primary_phone_number: data.contact.phoneNumber,
            secondary_number: data.contact.secondaryPhoneNumber,
          },
          employer_identification_number: data.ein,
          id: data.id,
          lineOfBusiness: data.linesOfBusiness,
          name: data.name,
          postal_code: data.address.zipCode,
          registration_date: data.registrationTime,
          state: data.address.state,
          website: data.websiteUrl,
          dba: data.doingBusinessAs,
          type: data.organizationType,
          working_week_start: data.workWeekDayStart,
        },
      });
    } finally {
      set({ loading: false });
    }
  },
}));
