import { ReactNode } from 'react';
import StepIcon, { stepIconClasses, StepIconProps } from '@mui/material/StepIcon';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { FaIcon } from '@atoms/icons';
import { Flex } from '@atoms/layout';

type WizardStepperProps = {
  activeStep?: number;
  steps?: {
    label: ReactNode;
    id: string;
  }[];
};
export function WizardStepper({ activeStep = 0, steps = [] }: WizardStepperProps) {
  return (
    <Stepper activeStep={activeStep} connector={<Connector />}>
      {steps.map(({ id, label }) => {
        return (
          <Step key={id}>
            <StepLabel StepIconComponent={StepOutlinedIcon}>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: `color-mix(in srgb, #fff 100%, ${theme.palette.primary.main} 8%)`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: `color-mix(in srgb, #fff 100%, ${theme.palette.primary.main} 8%)`,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: `color-mix(in srgb, #fff 100%, ${theme.palette.primary.main} 8%)`,
  },
}));

function StepOutlinedIcon(props: StepIconProps) {
  const theme = useTheme();
  if (props.completed) {
    return (
      <Flex
        border={`1px solid ${theme.palette.primary.main}`}
        borderRadius="50%"
        height={24}
        width={24}
        alignCenter
        justifyCenter
      >
        <FaIcon name="check" color={theme.palette.primary.main} />
      </Flex>
    );
  }

  if (props.active) {
    return <StepIcon {...props} />;
  }

  return <StepIconOutlined {...props} />;
}

const StepIconOutlined = styled(StepIcon)(({ theme }) => ({
  color: 'transparent',
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '50%',
  [`& .${stepIconClasses.text}`]: {
    fill: theme.palette.primary.main,
  },
}));
