import { create } from 'zustand';
import { createLocation, getLocation, getLocations, updateLocation } from '@libs/api/locations';
import { Location } from '@libs/models/locations';

type State = {
  locations: Location[];
  loading: boolean;
  total: number;
  loadLocations: () => Promise<void>;

  loadLocation: (locationId: string) => void;
  editLocationLoading: boolean;
  editLocationDetails: Location | null;
  flushEdit: () => void;

  createLocation: (location: Omit<Location, 'id'>) => Promise<void>;
  updateLocation: (id: string, location: Location) => Promise<void>;
};

export const useLocationStore = create<State>()((set) => ({
  locations: [],
  loading: false,
  total: 0,
  loadLocations: async () => {
    try {
      set({ loading: true });
      const { data } = await getLocations({
        size: 100,
        page: 1,
      });

      set({ locations: data, total: data.length });
    } finally {
      set({ loading: false });
    }
  },
  loadLocation: async (locationId: string) => {
    set({ editLocationLoading: true });
    try {
      const resp = await getLocation(locationId);
      set({
        editLocationDetails: resp.data,
      });
    } finally {
      set({ editLocationLoading: false });
    }
  },
  editLocationLoading: false,
  editLocationDetails: null,
  flushEdit: () => {
    set({
      editLocationLoading: false,
      editLocationDetails: null,
    });
  },

  createLocation: async (location) => {
    set({ editLocationLoading: true });
    try {
      await createLocation(location);
    } finally {
      set({ editLocationLoading: false });
    }
  },

  updateLocation: async (id, location) => {
    set({ editLocationLoading: true });
    try {
      await updateLocation(id, location);
    } finally {
      set({ editLocationLoading: false });
    }
  },
}));
