import { FormattedMessage } from 'react-intl';
import { Dayjs } from 'dayjs';
import { getShiftType } from '@libs/models/shifts/shift';

type ShiftLengthProps = {
  startTime: Dayjs;
  length: number;
};
export function ShiftLength({ startTime, length }: ShiftLengthProps) {
  const shiftType = getShiftType(startTime);
  return (
    <FormattedMessage
      id="shifts.lengthAndType"
      values={{
        length: Math.round(length / 1000 / 60 / 60),
        type: shiftType,
      }}
    />
  );
}
