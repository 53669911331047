import { create } from 'zustand';

type InitialState = {
  data: string[];
};

export const useLinesOfBusinessStore = create<InitialState>()((set) => ({
  data: [
    'AcuteCare',
    'AmbulatoryCare',
    'LongTermCare',
    'BehavioralHealth',
    'RehabilitationServices',
    'HomeHealthCare',
    'DiagnosticServices',
    'PreventiveAndWellnessServices',
    'PharmacyServices',
    'TelehealthAndTelemedicine',
    'AncillaryServices',
    'DentalServices',
    'VisionServices',
    'PublicHealthServices',
    'ResearchAndEducation',
  ],
}));

export function useLinesOfBusiness() {
  const { data } = useLinesOfBusinessStore();

  return data ?? [];
}
