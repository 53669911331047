import { request } from '../request';

export function deactivateTalents(ids: string[]) {
  return request.put<DeactivateTalentsResult>('/v2/staff/deactivate', ids);
}

type DeactivateTalentsResult = {
  deactivated: number;
  errors: {
    staffHasActiveShifts: { staffId: string; fullName: string }[];
  } | null;
};

export function activateTalents(ids: string[]) {
  return request.put('/v2/staff/activate', ids);
}
