import { create } from 'zustand';
import { fetchJobQualifications, saveOrgQualifications } from '@libs/api/talents';
import { Qualification } from '@libs/models/talents';

type InitialState = {
  loading: boolean;
  editDirectQualDrawerOpen: boolean;
  editOtherQualDrawerOpen: boolean;
  orgQualifications?: QualificationSettings;
  save: (settings: QualificationSettings) => Promise<void>;
  load: () => void;
  setDirectDrawerOpen: (isOpen: boolean) => void;
  setOtherDrawerOpen: (isOpen: boolean) => void;
};

export const useOrgQualificationsStore = create<InitialState>()((set) => ({
  loading: false,
  editDirectQualDrawerOpen: false,
  editOtherQualDrawerOpen: false,
  save: async (settings) => {
    if (!settings) return;
    try {
      await saveOrgQualifications(settings);
    } finally {
      set({ orgQualifications: settings });
    }
  },
  load: async () => {
    set({ loading: true });
    try {
      const setting = await fetchJobQualifications();
      set({ loading: false, orgQualifications: setting.data });
    } finally {
      set({ loading: false });
    }
  },
  setDirectDrawerOpen: (isOpen) => {
    set({ editOtherQualDrawerOpen: false });
    set({ editDirectQualDrawerOpen: !!isOpen });
  },
  setOtherDrawerOpen: (isOpen) => {
    set({ editDirectQualDrawerOpen: false });
    set({ editOtherQualDrawerOpen: !!isOpen });
  },
}));

type QualificationSettings = { nurse: Qualification[]; other: Qualification[] };
