import { styled, useTheme } from '@mui/material/styles';
import { RoundPaper, RoundPaperProps } from './roundPaper';

export type PrimaryPaperProps = RoundPaperProps;

export function PrimaryPaper({ ...props }: PrimaryPaperProps) {
  return <RoundPaperStyled {...props} />;
}

const RoundPaperStyled = styled(RoundPaper)(({ theme }) => ({
  backgroundColor: `color-mix(in srgb, #fff 100%, ${theme.palette.primary.main} 8%)`,
}));
