import { ReactNode, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Avatar } from '@atoms/dataDisplay';
import { Button } from '@atoms/buttons';
import { FaIcon } from '@atoms/icons';
import { Flex } from '@atoms/layout';

type ImageUploadFieldProps = {
  title: ReactNode;
  subtitle?: ReactNode;
  buttonTitle: ReactNode;
  deleteButtonTitle?: ReactNode;
  onChange?: (file: File | null) => void;
  initialImgSrc?: string | null;
  accept?: string;
  error?: string;
};
export function ImageUploadField({
  title,
  subtitle,
  buttonTitle,
  onChange,
  deleteButtonTitle = <FormattedMessage id="common.deleteImage" />,
  initialImgSrc,
  accept = '.png,.jpg,.jpeg',
  error,
}: ImageUploadFieldProps) {
  const [src, setSrc] = useState(initialImgSrc ?? '');
  const inputRef = useRef<HTMLInputElement>(null);
  const updateFile = (file: File | null) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        if (e.target?.result) {
          setSrc(e.target.result as string);
        }
      };
      reader.readAsDataURL(file);
    } else {
      setSrc('');
    }
    onChange?.(file);
  };

  return (
    <Flex gap={2}>
      <Flex>
        <Avatar sx={{ width: 64, height: 64 }} src={src} />
      </Flex>
      <Flex column>
        <Typography variant="subtitle2">{title}</Typography>
        {error ? (
          <Typography variant="caption" color="error">
            {error}
          </Typography>
        ) : (
          subtitle && <Typography variant="caption">{subtitle}</Typography>
        )}

        <Flex mt={2} gap={2}>
          <Button
            component="label"
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            size="small"
            startIcon={<FaIcon name="arrow-up-from-bracket" color="primary" />}
          >
            {buttonTitle}
            <VisuallyHiddenInput
              ref={inputRef}
              type="file"
              accept={accept}
              onChange={(event) => {
                const file = event.target.files?.[0] ?? null;
                updateFile(file);
              }}
            />
          </Button>
          <Button
            startIcon={<FaIcon name="trash-can" />}
            variant="passive"
            onClick={() => {
              updateFile(null);
              if (inputRef.current) {
                inputRef.current.value = '';
              }
            }}
          >
            {deleteButtonTitle}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
