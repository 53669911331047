import { flattenMessages } from '../flattenMessages';

export default flattenMessages({
  common: {
    saveChanges: 'Save changes',
    save: 'Save',
    cancel: 'Cancel',
    submit: 'Submit',
    weekDays: {
      sunday: 'Sunday',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
    },
    greeting: 'Hello, {name}!',
    clear: 'Clear',
    applyFilters: 'Apply filters',
    back: 'Back',
    next: 'Next',
    search: 'Search',
    filter: 'Filter',
    all: 'All',
    name: 'Name',
    clearAllFilters: 'Clear all filters',
    byPerson: 'by {name}',
    actions: 'Actions',
    assignDialog: {
      title: 'Assign',
      placeholder: 'Please, specify the value',
    },
    status: 'Status',
    location: 'Location',
    yes: 'Yes',
    no: 'No',
    unknownError: 'Something went wrong',
    edit: 'Edit',
    deleteImage: 'Delete image',
    fileValidationError: 'Wrong file format: image should be PNG or JPG format, {sizeMb}MB max.',
  },
  auth: {
    login: {
      title: 'Login',
      subtitle: 'Please, provide your email or phone number to receive an OTP',
      tabEmailTitle: 'Email',
      tabPhoneTitle: 'Phone number',
      emailPlaceholder: 'e.g. jhondoe@gmail.com',
      signUpText: 'Don’t have a ShiftNex account yet? <link>Sign Up</link>',
    },
    otp: {
      title: 'Secure verification',
      subtitle: 'We’ve just sent you a temporary 6-digit code verification. Please check email and/or phone.',
      inputPlaceholder: 'Enter the code',
      resendCode: 'Resend the code',
      resendCodeSuccess: 'Code successfully resent',
    },
    adminSignUp: {
      title: 'Welcome to ShiftNex!',
      titleWithName: '{name}, welcome to ShiftNex!',
      subtitle: 'Join the ShiftNex Healthcare Platform and enjoy the way we manage healthcare staffing operations',
      otpBySmsLabel: 'Receive OTP by sms',
      otpBySmsInstructions: 'Please, fill in your phone number if you want receive the one-time password  by SMS',
      submitLabel: 'Create account',
    },
    adminSignUpDetails: {
      title: 'Agency',
      subtitle: 'Please, fill in your agency details.',
    },
    adminSignUpAddress: {
      title: 'Address',
      subtitle: 'Please, fill in your agency address details.',
    },
    footer: {
      legalMessage:
        'By using {name} you agree to the <termsLink>Terms of Service</termsLink> and <privacyLink>Privacy Policy</privacyLink>',
      version: 'Version {version}',
    },
    submitLabel: 'Continue',
    signInText: 'Already have a ShiftNex account? <link>Sign In</link>',
  },
  sidebar: {
    poweredBy: 'Powered by ShiftNex',
    dashboard: 'Dashboard',
    clients: 'Clients',
    shifts: 'Shifts',
    talent: 'Providers',
    contacts: 'Contacts',
    marketplace: 'Marketplace',
    settings: 'Settings',
    billing: 'Billing',
    help: 'Help',
  },
  onboarding: {
    progress: {
      gettingStarted: 'Getting started',
      menuHeader: 'Onboarding',
    },
    organization: {
      title: 'Organization details',
      subtitle: 'Fill in your organization details',
      subtitleLong: 'Fill in basic information about your organization',
    },
    users: {
      title: 'Users',
      titleAlternative: 'Users',
      subtitle: 'Add users and set up the roles',
      subtitleLong: 'Create and invite system users of your organization',
    },
    qualifications: {
      title: 'Qualifications & Specialties',
      titleAlternative: 'Qualifications & Specialties',
      subtitle: 'Set up qualifications and specialties used in your organization.',
      subtitleLong:
        'Set up qualifications and specialties used in your organization. You can select them from default system list or create custom ones',
    },
    locations: {
      title: 'Locations',
      titleAlternative: 'Locations',
      subtitle: 'Here you can add and manage your organization’s locations',
      subtitleLong: 'Here you can add and manage your organization’s locations',
    },
    providers: {
      title: 'Providers',
      titleAlternative: 'Providers',
      subtitle: 'Add healthcare providers so you can assign them to shifts',
      subtitleLong: 'Add healthcare providers so you can assign them to shifts',
    },
    clients: {
      title: 'Clients',
      subtitle: 'Set up the relationship with facilities',
      subtitleLong:
        'View the facilities available on ShiftNex platform. Once they share shifts with you, you can propose your providers for assignment',
    },
    shifts: {
      title: 'Shifts',
      subtitle: 'Create and assign shifts',
      subtitleLong:
        'View the facilities available on ShiftNex platform. Once they share shifts with you, you can propose your providers for assignment',
    },
    widget: {
      hide: 'Hide',
      show: 'Show Onboarding',
      previousStep: 'Previous step',
      nextStep: 'Next step',
      addDetails: 'Add details',
      setBranding: 'Set branding',
      getStarted: 'Get started',
    },
  },
  license: {
    trial: {
      daysLeft: '{days} days left in trial',
      upgradeBtnLabel: 'Upgrade',
    },
  },
  header: {
    manageAccount: 'Manage account',
    profileMenu: {
      pauseNotificationsLabel: 'Pause notifications',
      profileSettingsLabel: 'Profile settings',
      supportLabel: 'Support chat',
      reportLabel: 'Report an issue',
      logOutLabel: 'Log out',
    },
    notificationSchedule: {
      label: 'Pause notifications for...',
      postponeMins: 'For {value, plural, one {# minute} other {# minutes}}',
      postponeHours: 'For {value, plural, one {# hour} other {# hours}}',
      postponeTomorrow: 'Until tomorrow',
    },
  },
  comingSoon: {
    title: 'Coming soon...',
    description: 'The page is not yet available but we are working on it',
  },
  settings: {
    menu: {
      general: {
        title: 'General',
      },
      organization: {
        title: 'Organization',
      },
      rolesAndUsers: {
        title: 'Roles and users',
      },
      groups: {
        title: 'Access groups',
      },
      users: {
        title: 'System users',
      },
      privacyAndSecurity: {
        title: 'Privacy and security',
      },
      organizationDetails: {
        title: 'Organization details',
      },
      documents: {
        title: 'Documents',
      },
      branding: {
        title: 'Branding',
      },
      qualificationsSpecialties: {
        title: 'Qualifications & Specialties',
      },
      qualifications: {
        title: 'Qualifications',
      },
      specialties: {
        title: 'Specialties',
      },
      locations: {
        title: 'Locations',
      },
    },
    organization: {
      savedSuccessfully: 'Organization details saved successfully',
      title: 'Organization details',
      subtitle: 'Please fill in your organization information so the ShiftNex can proceed with verifying your account',
      mainDetails: {
        title: 'Main details',
        subtitle: 'Please, fill in organization contact details and EIN',
      },
      address: {
        title: 'Address details',
        subtitle: 'Please, fill in your agency address details.',
      },
      contact: {
        title: 'Main organization contact',
        subtitle: 'Please, fill in contact details',
      },
      operational: {
        title: 'Operational setup',
        subtitle: 'Set up the way organization operates',
      },
    },
    qualSpeck: {
      qualifications: {
        title: 'Qualifications',
        subtitle:
          'In this section you can add qualifications used in your organization. You can select default values provided by the system or create your custom ones. Please note that qualification is mandatory for all users. Also, system default entities cannot be edited or deleted.',

        directQualBox: {
          title: 'Clinical',
          titleInfoTooltip: 'This category contains qualifications that can be applied to your providers',
          emptyListPlaceholderText: 'No qualifications inside "Nurses" category',
          editQualListButtonLabel: 'Edit qualifications',
        },
        editDirectQalDialog: {
          dialogHeading: 'Clinical',
          listCounter: '{amount} qualifications',
          addButton: 'Add qualification',
          selectTitle: 'Qualification',
          selectPlaceholder: 'Qualification',
          abbreviationTitle: 'Abbreviation',
          noAbbreviationLabel: 'no qualification chosen',
        },
        otherQualBox: {
          title: 'Other',
          titleInfoTooltip: 'In this category you can specify qualifications for your system users',
          emptyListPlaceholderText: 'No qualifications inside "Other" category',
          editQualListButtonLabel: 'Edit qualifications',
        },
        editOtherQalDialog: {
          dialogHeading: 'Other',
          listCounter: '{amount} qualifications',
          addButton: 'Add qualification',
          selectTitle: 'Qualification',
          abbreviationTitle: 'Abbreviation',
        },
      },
      specialties: {
        title: 'Specialties',
        subtitle:
          'Here you can add specialties of your facility. Each specialty should be mapped to one or multiple qualifications.',
        specialtiesCounter: '{amount} Specialties',
        addSpecialtyButtonLabel: 'Add specialty',
        table: {
          noRows: {
            title: 'No specialties yet',
            subtitle: "You don't have any specialties yet",
          },
          columns: {
            specialty: {
              heading: 'Specialty',
            },
            qualification: {
              heading: 'Qualification',
            },
          },
        },
        editSpecialtyDialog: {
          heading: 'Edit Specialty',
          selectTitle: 'Specialty title',
          selectTitlePlaceholder: 'Please select specialty',
          directQualificationsSectHeading: 'Clinical qualifications',
          otherQualificationsSectHeading: 'Other qualifications',
          errors: {
            qualNotSelected: 'Please select at least one qualification',
          },
        },
        addSpecialtyDialog: {
          heading: 'New Specialty',
          selectTitle: 'Specialty title',
          selectTitlePlaceholder: 'Please select specialty',
          directQualificationsSectHeading: 'Clinical qualifications',
          otherQualificationsSectHeading: 'Other qualifications',
          errors: {
            qualNotSelected: 'Please select at least one qualification',
          },
        },
      },
    },
    users: {
      title: 'System users',
      subtitle: 'Each user will belong to one of the roles below, and different roles have their unique privilege',
    },
    locations: {
      listPage: {
        title: 'Locations',
        subtitle:
          'This section displays the list of locations included in your facility. In ‘Structure’ sub-tab you can view and edit your organization hierarchical tree and it’s entities',
        locationsCounter: '{amount} Locations',
        addLocationButtonLabel: 'Add location',
        table: {
          noRows: {
            title: 'No locations yet',
            subtitle: "You don't have any locations yet",
          },
          columns: {
            name: {
              heading: 'Location name',
            },
            region: {
              heading: 'Region',
            },
            contact: {
              heading: 'Main contact',
            },
            email: {
              heading: 'Email',
            },
            status: {
              heading: 'Status',
            },
          },
        },
      },
      newLocationPage: {
        heading: 'New location',
      },
      editPage: {
        backButton: {
          label: 'Back to Locations',
        },
        tabs: {
          general: {
            label: 'General details',
            form: {
              main: {
                heading: 'Main details',
                subtitle: "Enter the location's basic details such as name and contact information.",
                locationName: {
                  label: 'Location name',
                  placeholder: 'Please add a name for location',
                },
                isActive: {
                  label: 'Location is active',
                },
                isHeadquarters: {
                  label: 'Location is a headquarters',
                },
              },
              address: {
                heading: 'Location address',
                subtitle: 'Provide the full address for this location.',
              },
              contact: {
                heading: 'Main location contact',
                subtitle: 'Add the primary contact person’s details for this location.',
                primaryContactName: {
                  label: "Primary contact's full name",
                  placeholder: 'Enter full name',
                },
                contactJobTitle: {
                  label: 'Job title',
                  placeholder: 'Enter job title',
                },
                primaryPhoneNumber: {
                  label: 'Primary phone number',
                  placeholder: 'Enter job title',
                },
                secondaryPhoneNumber: {
                  label: 'Secondary phone number',
                  placeholder: 'Enter job title',
                },
              },
              operational: {
                heading: 'Operational setup',
                subtitle: 'Choose the starting day of the operational week for this location.',
              },
            },
          },
          structure: {
            label: 'Location structure',
          },
        },
      },
    },
  },
  forms: {
    requiredFieldsMissing: '{number} mandatory fields missing',
    fieldIsRequired: 'This field is required',
    maxLengthError: 'Max length is {number}',
    invalidPhoneNumber: 'Please, enter valid phone number',
    invalidEIN: 'Please, check the format of the input',
    invalidZipCode: 'Please, enter valid zip code',
    invalidEmail: 'Please, enter valid email',
    emptyLinesOfBusiness: 'Select at least one business line of the organization',
    invalidWebsite: 'Please, enter valid website',
  },
  fields: {
    firstName: 'First name',
    firstNamePlaceholder: 'e.g. Jhon',
    lastName: 'Last name',
    lastNamePlaceholder: 'e.g. Doe',
    legalName: 'Legal name',
    agencyName: 'Agency name',
    agencyNamePlaceholder: 'Enter agency name',
    doingBusinessAs: 'Doing business as',
    agencyType: 'Agency type',
    agencyTypePlaceholder: 'Select agency type',
    registrationDate: 'Registration date',
    organizationWebsite: 'Organization website',
    organizationWebsitePlaceholder: 'Enter organization website',
    phone: 'Phone',
    phonePlaceholder: '+1 (XXX) XXX-XXXX',
    employerIdentificationNumber: 'Employer Identification Number (EIN)',

    primaryContactsFullName: 'Primary contact’s full name',
    primaryContactsFullNamePlaceholder: 'Enter primary contact’s full name',
    jobTitle: 'Job title',
    jobTitlePlaceholder: 'Enter job title',
    primaryPhoneNumber: 'Primary phone number',
    useOrganizationPhoneNumber: 'Use organization phone number',
    secondaryPhoneNumber: 'Secondary phone number',
    email: 'Email',
    emailPlaceholder: 'e.g. jhondoe@gmail.com',
    organizationsContactEmail: 'Enter organization’s contact email',

    addressLine1: 'Street address 1',
    addressLine1NoLocations: 'No locations',
    addressLine2: 'Street address 2',
    addressLinePlaceholder: 'Enter street name, number',
    cityAndState: 'City & State',
    zipCode: 'Zip code',

    weekStartDay: 'Working week start',
    linesOfBusiness: 'Lines of business of the organization',
    linesOfBusinessShort: 'Lines of business',
    linesOfBusinessPlaceholder: 'Select lines of business',
    jobQualifications: 'Provider Job Qualifications',
    jobQualificationsPlaceholder: 'Select qualifications that apply',
    qualificationsPlaceholder: 'Select the qualifications you staff',
    specialty: 'Specialty',

    talents: 'Providers',
    statuses: 'Statuses',

    minValueError: 'Minimum value is {value}',

    region: 'Region',
    regionHelper:
      'Region is the zone where your location operates. It is fetched from city and state indicated in location’s address',

    timezone: 'Timezone',
    timezonePlaceholder: 'Select a timezone',
  },
  shifts: {
    type: {
      shifts: 'Shifts',
      series: 'Series & Rotations',
    },
    recurrence: {
      single: 'Single',
      series: 'Series',
      rotation: 'Rotation',
    },
    status: {
      Assigned: 'Assigned',
      Reassigned: 'Re-assigned',
      Pending: 'Pending',
      InProgress: 'In progress',
      Completed: 'Completed',
      Canceled: 'Canceled',
      NotAllowed: 'N/A',
      Unfilled: 'Unfilled',
    },
    shiftType: {
      day8: '8h Day',
      eve8: '8h Eve',
      noc8: '8h Noc',
      day12: '12h Day',
      noc12: '12h Noc',
    },
    table: {
      noRows: {
        title: 'No shifts yet',
        subtitle: 'You don’t have any shifts yet',
      },
    },
    fields: {
      shiftType: 'Shift type',
      recurrence: 'Recurrence',
      location: 'Location',
      qualification: 'Qualification',
      qualifications: 'Qualifications',
      specialty: 'Specialty',
      status: 'Status',
      notes: 'Notes',
      assignee: 'Assignee',
      startDate: 'Start Date',
      shiftDate: 'Shift date',
      sharedWithin: 'Shared within',
      cityAndState: 'City & State',
      locationName: 'Location name',
      address: 'Address',
      department: 'Department',
      subDepartment: 'Sub department',
      talents: 'Providers',
    },
    lengthAndType: '{length}h, {type}',
    length: '{length}h',
    emptyNote: 'There is no note related to the shift',
    assignBtnLabel: 'Assign',
    assign: 'Assign',
    menuItemUnassign: 'Unassign',
    menuItemReAssign: 'Re-assign',
    assignSuccessful: 'Shift has been assigned successfully',
    unassignSuccessful: 'Shift has been unassigned successfully',
    unassignDialog: {
      title: 'Unassign shift',
      subtitle: 'Please, specify the reason',
      reasonLabel: 'Reason of unassigning',
      reasonPlaceholder: 'Select the reason',
      shiftDetails: '{period}, {length} {type}, {qualifications}, {assignee}',
      reasonCommentLabel: 'What is the issue?',
      reasonCommentPlaceholder: 'Please, specify the reason',
    },
    assignDialog: {
      title: 'Assign shift',
      placeholder: 'Please, specify the provider',
      shiftDetails: '{period}, {length} {type}, {qualifications}, {assignee}',
    },
    startDateAll: 'All',
    shiftDetails: 'Shift details',
    locationDetails: 'Location details',
    mainScheduler: 'Main scheduler',
    afterHoursScheduler: 'After-hours scheduler',
    generalInfo: 'General info',
    notes: 'Notes',
    billing: 'Billing',
    dayOneInstructions: 'Day one instructions',
    additionalNotes: 'Additional notes',
    historyLog: 'History log',
    logs: {
      created: 'Shift created',
      shared: 'Shift shared',
      assigned: 'Shift assigned:',
      started: 'Shift started:',
      shiftTime: '{date}, {timeRange}, {shiftLength}h {shiftType}',
    },
  },
  billing: {
    bonusRate: 'Bonus rate',
    rate: {
      perHour: 'Per hour',
      perShift: 'Per shift',
    },
    rates: 'Rates',
    totals: 'Totals',
    billRate: 'Bill rate',
    payRate: 'Pay rate',
    bonusAmount: 'Bonus amount',
    billAmount: 'Bill amount',
    payAmount: 'Pay amount',
    grossMargin: 'Gross margin',
    totalTime: 'Total time',
  },
  filters: {
    dateRange: {
      previousQuarter: 'Previous quarter',
      previousWeek: 'Previous week',
      today: 'Today',
      thisWeek: 'This week',
      thisQuarter: 'This quarter',
      nextWeek: 'Next week',
      nextQuarter: 'Next quarter',
    },
    sharedWithin: {
      menuLabel: 'Shared within',
      last24Hours: 'Last 24 hours',
      last48Hours: 'Last 48 hours',
      allTime: 'All time',
      last7Days: 'Last 7 days',
      last30Days: 'Last 30 days',
    },
  },
  talents: {
    labelOne: 'Provider',
    addButtonLabel: 'Add provider',
    addMenu: {
      addSingle: 'Individual provider',
      csvImport: 'CSV import',
    },
    qualifications: 'Qualifications',
    lastWorked: 'Last worked',
    statuses: {
      active: 'Active',
      deactivated: 'Deactivated',
      deleted: 'Deleted',
      invitefailed: 'Invite failed',
      invited: 'Invited',
      pending: 'Pending',
    },
    qualification: 'Qualification',
    specialty: 'Specialty',
    editForm: {
      header: 'Add internal provider',
      editHeader: 'Edit internal provider',
      personalInformation: 'Personal Information',
      profileImageTitle: 'Upload profile image',
      profileImageDisclaimer: 'Image should be PNG or JPG format, 2MB max.',
      profileImageSubmitTitle: 'Upload image',
      contactInformation: 'Contact Information',
      hrInformation: 'HR Information',
      primaryRegion: 'Primary region',
      secondaryRegion: 'Secondary region',
      roleAndPosition: 'Location and job title',
      qualificationAndSpecialties: 'Qualification and specialties',
      specialtiesApplied: 'Specialties applied',
      specialties: 'Specialties',
      rateType: 'Rate type',
      baseRate: 'Base rate',
      deleteQualification: 'Delete qualification',
      addQualification: 'Add qualification',
      deleteSpecialty: 'Delete specialty',
      addSpecialty: 'Add specialty',
      travel: 'Travel',
      availableForTravel: 'Available for travel',
      schedule: 'Schedule',
      success: 'Provider has been created successfully',
      successEdit: 'Provider has been edited successfully',
      maxTravelDistance: 'Max. travel distance (in miles)',
      stepGeneral: 'General',
      stepRoleAndPay: 'Role & Pay rate',
      stepAvailability: 'Availability',
    },
    rateTypes: {
      hourly: 'Hourly',
      monthly: 'Monthly',
      yearly: 'Yearly',
    },
    table: {
      noRows: {
        title: 'No providers yet',
        subtitle: 'You don’t have any providers yet',
      },
    },
    deactivateUser: 'Deactivate User',
    deactivateUsers: 'Deactivate user(s)',
    activateUser: 'Activate User',
    activateUsers: 'Activate User(s)',
    talentsHaveActiveShiftsError: `{count, plural, one {Provider {items} has} other {Providers {items} have}} active shifts. Please unassign them before deactivating {count, plural, one {the provider} other {providers}}`,
    talentHasActiveShiftsError:
      'You cannot deactivate provider with scheduled shifts. To proceed, reassign their shifts to another provider before attempting deactivation',
    sendInvites: 'Send invite(s)',
    resendInvites: 'Resend invite(s)',
    staffingType: {
      label: 'Staffing type',
      internal: 'Internal',
      agency: 'Agency',
      directContractor: 'Direct contractor',
    },
    fields: {
      specialities: 'Specialities',
      nurseQualifications: 'Qualifications',
    },
    deactivateSuccess: '{count, plural, one {Provider has} other {# providers have}} been deactivated successfully',
    activateSuccess: '{count, plural, one {Provider has} other {# providers have}} been activated successfully',
    employmentType: {
      label: 'Employment type',
      Contract: 'Contract',
      SalariedPartTime: 'Salaried Part Time',
      SalariedFullTime: 'Salaried Full Time',
      HourlyPartTime: 'Hourly Part Time',
      HourlyFullTime: 'Hourly Full Time',
      PerDiemOrPRN: 'Per Diem or PRN',
    },
  },
  clients: {
    table: {
      noRows: {
        title: 'No clients yet',
        subtitle: 'You don’t have any ,client’s requests yet',
      },
      noLocations: {
        title: 'No locations yet',
        subtitle: 'No locations yet',
      },
    },
    viewSwitcher: {
      clients: 'Clients',
      locations: 'Locations',
    },
    locationsNumber: '{number, plural, =0 {-} one {# location} other {# locations} }',
    contract: {
      label: 'Contract',
      direct: 'Direct',
      managed: 'Managed',
    },
    regions: 'Regions',
    openShifts: 'Open shifts',
    lastWorked: 'Last worked',
    completedShifts: 'Completed shifts',
    inviteStatus: {
      label: 'Invite status',
      active: 'Active',
      disconnected: 'Disconnected',
      pending: 'Pending',
    },
    manager: 'Manager',
    managers: 'Managers',
    assignManager: 'Assign manager',
  },
  users: {
    status: {
      InviteFailed: 'Invite failed',
      Invited: 'Invited',
      Pending: 'Pending',
      Active: 'Active',
      Deactivated: 'Deactivated',
    },
    usersCount: '{number, plural, =0 {No users} one {# user} other {# users} }',
    table: {
      noRows: {
        title: 'No users yet',
        subtitle: 'You don’t have any users yet added',
      },
    },
    addUserButtonLabel: 'Add user',
    userForm: {
      title: 'Add system user',
      editTitle: 'Edit system user',
      stepGeneral: 'General',
      stepEmployment: 'Employment & Qualifications',
      success: 'User has been created successfully',
      submitNew: {
        label: 'Invite',
      },
    },
    error: {
      409: 'User with this email already exists',
      image: {
        InvalidSize: 'Image size is invalid',
      },
    },
  },
  linesOfBusiness: {
    AcuteCare: 'Acute Care',
    AmbulatoryCare: 'Ambulatory Care',
    LongTermCare: 'Long-Term Care',
    BehavioralHealth: 'Behavioral Health',
    RehabilitationServices: 'Rehabilitation Services',
    HomeHealthCare: 'Home Health Care',
    DiagnosticServices: 'Diagnostic Services',
    PreventiveAndWellnessServices: 'Preventive and Wellness Services',
    PharmacyServices: 'Pharmacy Services',
    TelehealthAndTelemedicine: 'Telehealth and Telemedicine',
    AncillaryServices: 'Ancillary Services',
    DentalServices: 'Dental Services',
    VisionServices: 'Vision Services',
    PublicHealthServices: 'Public Health Services',
    ResearchAndEducation: 'Research and Education',
  },
  locations: {
    status: {
      Active: {
        tableChip: {
          label: ' Active',
        },
      },
      Deactivated: {
        tableChip: {
          label: 'Deactivated',
        },
      },
    },
  },
});
