import { SupportChatUserContext, UserData } from '@shiftnex/framework/src/components/molecules/supportChat';
import { useAuthStore } from '@libs/store/auth';
import { PropsWithChildren } from 'react';

export function SupportChatUserContextProvider({ children }: PropsWithChildren) {
  const { userData } = useAuthStore();
  const supportChatUserData: UserData | null = userData ? { ...userData, tenantId: location.host } : null;

  return <SupportChatUserContext.Provider value={supportChatUserData}>{children}</SupportChatUserContext.Provider>;
}
