import { Dayjs } from 'dayjs';
import { Qualification, QualificationPayRateFormData } from './jobQualification';
import { Staff, StaffResponse } from '../staff';

export type TalentListItem = {
  id: string;
  staffingType: 'Internal' | 'Agency' | 'DirectContractor';
  staffingRoles: string[];
  employmentType: 'FullTime' | 'PartTime';
  imageUrl?: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  addressDetails: {
    firstAddress: string;
    secondAddress?: string;
    city: string;
    state: string;
    zipCode: string;
  };
  hourlyPayRate: number;
  staffingStatus: StaffingStatus;
  nurseQualifications: Qualification[];
  specialities: {
    id: string;
    name: string;
    category: string;
  }[];
  lastWorkedTime: Dayjs;
  location: {
    id: string;
    name: string;
  };
};
export type TalentListItemResponse = Omit<TalentListItem, 'lastWorkedTime'> & { lastWorkedTime: string };

export type TalentData = TalentEditGeneralData & TalentEditRolePayRateData & TalentEditAvailabilityData;

export type TalentEditGeneralData = {
  image: File | null;
  imageUrl?: string | null;
  isImageUrlNeedToBeUpdated: boolean;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  status: StaffingStatus;
  address: {
    firstAddress: string;
    city: string;
    state: string;
    zipCode: string;
    secondAddress: string;
  };
  hrInfo: {
    primaryRegion: {
      city: string;
      state: string;
    };
    secondaryRegion: {
      city: string;
      state: string;
    };
  };
};

export type TalentEditRolePayRateData = {
  locationId: string;
  jobTitle: string;
  qualificationPayRates: QualificationPayRateFormData[];
};

export type TalentEditAvailabilityData = {
  employmentType: string;
  availableForTravelDistance: number;
  workShifts: {
    dayOfWeek: string;
    startTime: string;
    duration: string;
  }[];
};

export type Talent = Staff;

export type TalentResponse = StaffResponse;

export type StaffingStatus = 'InviteFailed' | 'Invited' | 'Pending' | 'Active' | 'Deactivated' | 'Deleted';

export class StaffHasActiveShiftsError extends Error {
  data: StaffHasActiveShiftsErrorDetails;

  constructor(data: StaffHasActiveShiftsErrorDetails) {
    super('Staff has active shifts');
    this.name = 'StaffHasActiveShiftsError';
    this.data = data;
  }
}

export type StaffHasActiveShiftsErrorDetails = {
  detail: string;
  instance: string;
  staff: {
    fullName: string;
    staffId: string;
  }[];
  status: 403;
  title: 'staffHasActiveShifts';
  traceId: string;
  type: 'staffHasActiveShifts';
};
