import { Filter, PaginatedResponse } from '@libs/models/common';
import { TalentListItemResponse } from '@libs/models/talents';
import { filterToQueryParams } from '../utils/filterToQueryParams';
import { request } from '../request';

export type GetTalentsParams = {
  orderBy?: { field: string; sort: string }[];
  search?: string;
  page?: number;
  size?: number;
  filters?: {
    field: string;
    value: Filter['value'];
  }[];
};
export function getTalents(params?: GetTalentsParams) {
  const filters = filterToQueryParams(params?.filters ?? []);
  return request.get<PaginatedResponse<TalentListItemResponse[]>>('/v2/staff', {
    params: {
      pageNumber: params?.page,
      pageSize: params?.size,
      orderBy_in: params?.orderBy?.map((p) => {
        return `${p.field}_${p.sort}`;
      }),
      search: params?.search,
      ...filters,
    },
  });
}
