import { DuotoneIcon, OrganizationIcon, ShiftsIcon, UsersIcon } from '@atoms/icons';

export const onboardingItems = [
  {
    id: 'organization',
    icon: <OrganizationIcon active />,
    titleKey: 'onboarding.organization.title',
    subtitleKey: 'onboarding.organization.subtitle',
    subtitleLongKey: 'onboarding.organization.subtitleLong',
    actionButtonLabel: 'onboarding.widget.getStarted',
    actionLink: '/settings/organization/details',
  },
  {
    id: 'users',
    icon: <UsersIcon active />,
    titleKey: 'onboarding.users.title',
    titleAlternativeKey: 'onboarding.users.titleAlternative',
    subtitleKey: 'onboarding.users.subtitle',
    subtitleLongKey: 'onboarding.users.subtitleLong',
    actionButtonLabel: 'onboarding.widget.getStarted',
    actionLink: '/settings/users',
  },
  {
    id: 'qualifications',
    icon: <DuotoneIcon name="notes-medical" active />,
    titleKey: 'onboarding.qualifications.title',
    titleAlternativeKey: 'onboarding.qualifications.titleAlternative',
    subtitleKey: 'onboarding.qualifications.subtitle',
    subtitleLongKey: 'onboarding.qualifications.subtitleLong',
    actionButtonLabel: 'onboarding.widget.getStarted',
    actionLink: '/settings/qualifications-specialties',
  },
  {
    id: 'locations',
    icon: <DuotoneIcon name="map-location-dot" active />,
    titleKey: 'onboarding.locations.title',
    titleAlternativeKey: 'onboarding.locations.titleAlternative',
    subtitleKey: 'onboarding.locations.subtitle',
    subtitleLongKey: 'onboarding.locations.subtitleLong',
    actionButtonLabel: 'onboarding.widget.getStarted',
    actionLink: '/settings/locations',
  },
  {
    id: 'providers',
    icon: <DuotoneIcon name="user-doctor" active />,
    titleKey: 'onboarding.providers.title',
    titleAlternativeKey: 'onboarding.providers.titleAlternative',
    subtitleKey: 'onboarding.providers.subtitle',
    subtitleLongKey: 'onboarding.providers.subtitleLong',
    actionButtonLabel: 'onboarding.widget.getStarted',
    actionLink: '/providers',
  },
  {
    id: 'clients',
    icon: <DuotoneIcon name="handshake" active />,
    titleKey: 'onboarding.clients.title',
    subtitleKey: 'onboarding.clients.subtitle',
    subtitleLongKey: 'onboarding.clients.subtitleLong',
    actionButtonLabel: 'onboarding.widget.getStarted',
    actionLink: '/clients',
  },
  {
    id: 'shifts',
    icon: <ShiftsIcon active />,
    titleKey: 'onboarding.shifts.title',
    subtitleKey: 'onboarding.shifts.subtitle',
    subtitleLongKey: 'onboarding.shifts.subtitleLong',
    actionButtonLabel: 'onboarding.widget.getStarted',
    actionLink: '/shifts',
  },
];
