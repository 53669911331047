import { TalentData } from '@libs/models/talents';
import { request } from '../request';

export function editTalent(
  id: string,
  data: Omit<TalentData, 'status'> & {
    isImageUrlNeedToBeUpdated: boolean;
  },
) {
  const form = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (key === 'image') {
      if (data.isImageUrlNeedToBeUpdated) {
        if (value) {
          form.append(key, value as File);
        }
      }
      return;
    }
    if (key === 'availableForTravelDistance') {
      form.append(key, (value as string) ?? '0');
      return;
    }
    if (typeof value === 'object' && value !== null) {
      form.append(key, JSON.stringify(value));
      return;
    }

    form.append(key, value?.toString() ?? '');
  });

  return request.patch<string>(`/v2/staff/${id}`, form);
}
