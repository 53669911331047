import Paper, { PaperProps } from '@mui/material/Paper';
import { styled, useTheme } from '@mui/material/styles';

export type RoundPaperProps = PaperProps;

export function RoundPaper({ children, ...props }: RoundPaperProps) {
  return <PaperStyled {...props}>{children}</PaperStyled>;
}

const PaperStyled = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
}));
