import { request } from '../request';

export function getFreshToken(token: string) {
  return request.post<{
    token: string;
    expireTime: string;
    refreshToken: string;
    refreshExpireTime: string;
  }>('/v2/identity/refresh', {
    token,
  });
}
