import { DuotoneIcon } from '@atoms/icons';
import { Box } from '@atoms/layout';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export function ComingSoonBanner() {
  return (
    <>
      <Box fontSize="80px" mb={3}>
        <DuotoneIcon name="bulldozer" active />
      </Box>
      <Typography variant="h5" fontSize="28px">
        <FormattedMessage id="comingSoon.title" />
      </Typography>
      <Box mt={1.5}>
        <Typography width="344px">
          <FormattedMessage id="comingSoon.description" />
        </Typography>
      </Box>
    </>
  );
}
