import { request } from '../request';
import { OrganizationDetailsServer } from '@libs/models/settings/organizationDetails';

export function saveOrganizationDetails(data: Omit<OrganizationDetailsServer, 'id'>) {
  return request.put('/v2/settings/details', data);
}

export function getOrganizationDetails() {
  return request.get<OrganizationDetailsServer>('/v2/settings/details');
}
