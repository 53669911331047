import { Qualification } from '@libs/models/talents';
import { request } from '../request';

export function fetchJobQualifications() {
  return request.get<{ nurse: Qualification[]; other: Qualification[] }>('/v2/settings/qualifications');
}

export async function saveOrgQualifications(setting: {
  nurse: Qualification[];
  other: Qualification[];
}): Promise<void> {
  return request.put('/v2/settings/qualifications', {
    nurses: setting.nurse,
    other: setting.other,
  });
}

export async function getDirectQualifications(): Promise<Qualification[]> {
  const response = await request.get<Qualification[]>('/v2/nurses/qualifications');

  return response.data;
}
