export class ErrorWithDetails extends Error {
  details: ErrorDetails;
  constructor(message: string, details: ErrorDetails) {
    super(message);
    this.name = 'AppError';
    this.details = details;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ErrorDetails = { type: string; values: Record<string, any> };
