import React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ButtonGroup, { ButtonGroupProps } from '@mui/material/ButtonGroup';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import AddIcon from '@mui/icons-material/Add';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Grow from '@mui/material/Grow';
import { Button, ButtonProps } from './button';
import { PopperProps } from '@mui/material/Popper/BasePopper.types';
import { styled } from '@mui/material';

type MenuItem = {
  label: string;
  onAction?: () => void;
};

type MenuSplitButtonProps = {
  label: string;
  onMainClick: () => void;
  onSecondaryClick?: () => void;
  menuItems?: MenuItem[];
  mainButtonIcon?: React.ReactNode;
  dropDownIcon?: React.ReactNode | JSX.Element;
  buttonGroupProps?: ButtonGroupProps;
  mainButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
  popperProps?: PopperProps;
};

export function MenuSplitButton({
  onMainClick,
  label,
  onSecondaryClick,
  menuItems,
  mainButtonIcon,
  dropDownIcon,
  buttonGroupProps,
  mainButtonProps,
  secondaryButtonProps,
  popperProps,
}: MenuSplitButtonProps) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleMainButtonClick = () => {
    onMainClick && onMainClick();
  };

  const handleSecondaryButtonClick = () => {
    onSecondaryClick && onSecondaryClick();
    handleToggle();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup variant="contained" ref={anchorRef} aria-label={label} {...buttonGroupProps}>
        <Button
          onClick={handleMainButtonClick}
          size="large"
          startIcon={mainButtonIcon ? mainButtonIcon : <AddIcon />}
          {...mainButtonProps}
        >
          {label}
        </Button>
        <Button
          size="large"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select option"
          aria-haspopup="menu"
          onClick={handleSecondaryButtonClick}
          {...secondaryButtonProps}
        >
          {dropDownIcon ? dropDownIcon : <ArrowDropDownIcon />}
        </Button>
      </ButtonGroup>

      <StyledPopper anchorEl={anchorRef.current} role={undefined} transition disablePortal {...popperProps} open={open}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {menuItems &&
                    menuItems.map((item, idx) => (
                      <MenuItem
                        key={idx}
                        onClick={() => {
                          handleToggle();
                          item.onAction && item.onAction();
                        }}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
    </>
  );
}

const StyledPopper = styled(Popper)({
  zIndex: 10,
});
