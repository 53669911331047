import { create } from 'zustand';
import dayjs from 'dayjs';
import { fetchShiftsList } from '@libs/api/shifts/fetchShiftsList';
import { ShiftFilter } from '@libs/models/shifts/shift';
import { Shift } from '@libs/models/shifts';

type State = {
  loading: boolean;
  initiated: boolean;
  data: Shift[];
  page: number;
  total: number;
  perPage: number;
  load: (page: number, perPage: number, filter?: ShiftFilter) => Promise<void>;
  showDetails: boolean;
  currentShift: Shift | null;
  openShiftDetails: (id: string) => void;
  closeShiftDetails: () => void;
};

export const useShiftsListStore = create<State>()((set, get) => ({
  loading: false,
  initiated: false,
  data: [],
  page: 1,
  perPage: 10,
  total: 0,
  load: async (page, perPage, filter) => {
    set({ loading: true });
    try {
      const { data } = await fetchShiftsList(page, perPage, {
        ...filter,
        startDate: filter?.startDate ?? undefined,
        endDate: filter?.endDate ?? undefined,
      });
      set({
        loading: false,
        data: data.items.map((data) => ({
          ...data,
          shiftStart: dayjs(data.startDate),
          shiftLength: new Date(data.endDate).getTime() - new Date(data.startDate).getTime(),
          recurrence: 'single',
          qualifications: data.qualificationToPayFilters,
          specialty: data.specialityToPay?.title || undefined,
          assignee: data.staff && {
            ...data.staff,
            // TODO: get from server
            assignedByClient: false,
            avatar: data.staff.imageUrl,
            name: [data.staff.firstName, data.staff.lastName].filter(Boolean).join(' '),
          },
          location: {
            name: data.location.name,
            city: data.location.city,
            state: data.location.state,
            address1: data.location.firstAddress,
            address2: data.location.secondAddress,
          },
        })),
        page: data.pageNumber,
        perPage: data.pageSize,
        total: data.totalCount,
      });
    } finally {
      set({ loading: false, initiated: true });
    }
  },
  currentShift: null,
  showDetails: false,
  openShiftDetails: (id) => {
    const { data } = get();
    const shift = data.find((s) => s.id === id);
    set({ currentShift: shift, showDetails: true });
  },
  closeShiftDetails: () => {
    set({ showDetails: false });
  },
}));
