import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export const isPhoneValid = (phone: string, region?: string) => {
  try {
    return phoneUtil.isValidNumberForRegion(phoneUtil.parseAndKeepRawInput(phone), region);
  } catch (error) {
    return false;
  }
};
