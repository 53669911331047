import { PropsWithChildren, useEffect, useState } from 'react';
import { CHATWOOT_APP_ID } from '@libs/config';
import { useSupportChatUser, UserData } from '../userContext';

declare global {
  interface Window {
    $chatwoot: any;
    chatwootSDK: any;
    chatwootSettings: any;
  }
}

export function useChatwootSDK() {
  const [chatwootReady, setIsLoaded] = useState(false);

  useEffect(() => {
    const handleChatwootReady = () => {
      setIsLoaded(true);
    };

    window.addEventListener('chatwoot:ready', handleChatwootReady);

    return () => {
      window.removeEventListener('chatwoot:ready', handleChatwootReady);
    };
  }, []);

  const openChatWindow = () => {
    window.$chatwoot.popoutChatWindow();
  };

  const toggleOpenChatWindow = () => {
    window.$chatwoot.toggle('open');
  };

  const toggleCloseChatWindow = () => {
    window.$chatwoot.toggle('close');
  };

  const hideChatBubble = () => {
    window.$chatwoot.toggleBubbleVisibility('hide');
  };

  const showChatBubble = () => {
    window.$chatwoot.toggleBubbleVisibility('show');
  };

  const setUserData = (userData: UserData) => {
    window.$chatwoot.setUser(userData.id, {
      email: userData.email,
      name: `${userData.firstName} ${userData.lastName}`,
      company_name: userData.tenantId,
    });
  };

  return {
    chatwootReady,
    openChatWindow,
    hideChatBubble,
    showChatBubble,
    toggleOpenChatWindow,
    toggleCloseChatWindow,
    setUserData,
  };
}

export function ChatwootWrapper({ children }: PropsWithChildren) {
  const { chatwootReady, setUserData } = useChatwootSDK();
  const userData = useSupportChatUser();

  useEffect(() => {
    window.chatwootSettings = {
      hideMessageBubble: true,
    };
    if (userData) {
      (function (doc: Document, tag: string) {
        const BASE_URL = 'https://app.chatwoot.com';
        const chatwootScript = doc.createElement(tag) as HTMLScriptElement;
        const scriptElement = doc.getElementsByTagName(tag)[0] as HTMLScriptElement;
        chatwootScript.src = BASE_URL + '/packs/js/sdk.js';
        chatwootScript.defer = true;
        chatwootScript.async = true;
        if (scriptElement.parentNode) {
          scriptElement.parentNode.insertBefore(chatwootScript, scriptElement);
        }
        chatwootScript.onload = function () {
          window.chatwootSDK.run({
            websiteToken: CHATWOOT_APP_ID,
            baseUrl: BASE_URL,
          });
        };
      })(document, 'script');
    }
  }, [userData]);

  useEffect(() => {
    if (chatwootReady && userData) {
      setUserData(userData);
    }
  }, [chatwootReady, userData, setUserData]);

  return children;
}
