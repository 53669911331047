import { useEffect } from 'react';
import { useAuthStore } from '@libs/store/auth';
import { Routing } from './Routing.tsx';

export function App() {
  useTokenRefresher();
  return (
    <>
      <Routing />
    </>
  );
}

function useTokenRefresher() {
  const { initRefreshTokenProcedure } = useAuthStore();
  useEffect(() => {
    initRefreshTokenProcedure();
  }, [initRefreshTokenProcedure]);
  return null;
}
