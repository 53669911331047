import { forwardRef, PropsWithChildren } from 'react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material';

export type ButtonProps = MuiButtonProps & {
  rounded?: boolean;
};

export const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(function Button(
  { children, rounded = true, ...props },
  ref,
) {
  return (
    <StyledButton ref={ref} rounded={rounded} {...props}>
      {children}
    </StyledButton>
  );
});

const StyledButton = styled(MuiButton, { shouldForwardProp: (prop: string) => !['rounded'].includes(prop) })<{
  rounded?: boolean;
}>(({ rounded }) => ({
  px: 2,
  borderRadius: rounded ? '100px' : undefined,
}));
