import { createContext, useContext } from 'react';

export type UserData = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  tenantId: string;
};

export const SupportChatUserContext = createContext<UserData | null>(null);

export const useSupportChatUser = () => useContext(SupportChatUserContext);
