import { styled, useTheme } from '@mui/material';
import logoImage from '@framework/assets/logo_ax.png';
import logoText from '@framework/assets/logoText_ax.svg';
import { Flex, FlexProps } from './layout';

export const AppLogo = () => {
  const theme = useTheme();
  return (
    <Flex
      alignCenter
      justifyCenter
      border={`1px solid ${theme.palette.grey[300]}`}
      width="40px"
      height="40px"
      borderRadius="50%"
    >
      <img src={logoImage} alt="Logo" height="22px" />
    </Flex>
  );
};

export const AppLogoText = (props: FlexProps) => {
  return (
    <StyledFlex alignCenter justifyCenter {...props}>
      <img src={logoText} alt="Powered by logo" width="100%" />
    </StyledFlex>
  );
};

const StyledFlex = styled(Flex)`
  margin-top: 16px;
  margin-bottom: 24px;
`;
