import { Dayjs } from 'dayjs';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getShiftType } from '@libs/models/shifts/shift';
import { Box, Flex, type FlexProps } from '@atoms/layout';
import { ShiftLength, TimeRange } from '@molecules/dataDisplay';

type ShiftTypeProps = {
  startTime: Dayjs;
  length: number;
  boxProps?: FlexProps;
};
export function ShiftType({ startTime, length, boxProps }: ShiftTypeProps) {
  return (
    <Flex alignCenter gap={1} {...boxProps}>
      <ShiftTypeIndicator startTime={startTime} />
      <Flex column maxWidth="100%">
        <Typography variant="body2" noWrap>
          <ShiftRange startTime={startTime} length={length} />
        </Typography>

        <Typography variant="caption" color="text.secondary">
          <ShiftLength startTime={startTime} length={length} />
        </Typography>
      </Flex>
    </Flex>
  );
}
export function ShiftTypeInline({ startTime, length, boxProps }: ShiftTypeProps) {
  return (
    <Flex alignCenter gap={1} {...boxProps}>
      <ShiftTypeIndicator startTime={startTime} />
      <Flex maxWidth="100%">
        <Typography variant="body2" noWrap>
          <ShiftRange startTime={startTime} length={length} />
          {', '}
          <ShiftLength startTime={startTime} length={length} />
        </Typography>
      </Flex>
    </Flex>
  );
}

type ShiftTypeIndicatorProps = {
  startTime: Dayjs;
};
function ShiftTypeIndicator({ startTime }: ShiftTypeIndicatorProps) {
  const theme = useTheme();
  const shiftType = getShiftType(startTime);
  const shiftTypeToColor = {
    Day: theme.palette.shiftType.day,
    Eve: theme.palette.shiftType.eve,
    Noc: theme.palette.shiftType.noc,
  };
  return <Box width={10} height={10} bgcolor={shiftTypeToColor[shiftType]} borderRadius="50%" flexShrink={0} />;
}

type ShiftRangeProps = {
  startTime: Dayjs;
  length: number;
};
function ShiftRange({ startTime, length }: ShiftRangeProps) {
  const to = startTime.add(length, 'millisecond');
  return <TimeRange from={startTime} to={to} />;
}
