import { forwardRef } from 'react';
import { Dayjs } from 'dayjs';
import { TimePicker as MuiTimePicker, TimePickerProps as MuiTimePickerProps } from '@mui/x-date-pickers/TimePicker';
import { TextField } from './textField';
// To make Dayjs as valid generic
import '@mui/x-date-pickers/AdapterDayjs';

export type TimePickerProps = MuiTimePickerProps<Dayjs> & {
  fullWidth?: boolean;
  error?: boolean;
  required?: boolean;
  helperText?: React.ReactNode;
  placeholder?: string;
};
export const TimePicker = forwardRef<HTMLDivElement, TimePickerProps>(function DatePicker(
  { fullWidth, error, helperText, required, placeholder, ...props }: TimePickerProps,
  ref,
) {
  return (
    <MuiTimePicker
      slots={{ textField: TextField }}
      slotProps={{
        textField: {
          fullWidth,
          required,
          error,
          helperText,
          inputRef: ref,
          placeholder,
        },
      }}
      {...props}
    />
  );
});
