import { Dayjs } from 'dayjs';

export type Shift = {
  id: string;
  shiftStart: Dayjs;
  // In milliseconds
  shiftLength: number;
  // TODO: check Intl when real statuses are available
  recurrence: 'single' | 'series' | 'rotation';
  location: {
    name: string;
    address1: string;
    address2?: string;
    city: string;
    state: string;
  };
  qualifications: {
    id: string;
    name: string;
    abbreviation: string;
    category: string;
  }[];
  specialty?: string;
  status: ShiftStatus;
  notes?: string;
  assignee: Assignee | null;
  locationDetails?: {
    department?: string;
    subDepartment?: string;
    primaryPhoneNumber: string;
    secondaryPhoneNumber: string;
  };
  mainScheduler?: {
    name: string;
    jobTitle: string;
    primaryPhoneNumber: string;
    email: string;
  };
  afterHoursScheduler?: {
    name: string;
    jobTitle: string;
    primaryPhoneNumber: string;
    email: string;
  };
};

export type ShiftFilter = {
  startDate?: string | null;
  endDate?: string | null;
  externalLocationNames?: string[];
  qualificationIds?: string[];
  staffIds?: string[];
  statuses?: ShiftStatus[];
  specialityIds?: string[];
  facilityTenantIds?: string[];
  shiftTimeTypes?: ShiftType[];
};

type ShiftStatus =
  | 'Pending'
  | 'Assigned'
  | 'Reassigned'
  | 'NotAllowed'
  | 'Canceled'
  | 'InProgress'
  | 'Unfilled'
  | 'Completed';

type Assignee = {
  // TODO: check rendering when real avatars are available
  avatar?: string;
  name: string;
  assignedByClient: boolean;
  specialty?: string;
};

export type ShiftType = 'Day' | 'Eve' | 'Noc';

// Shifts starting between 4am and 11:59AM are categorized as day shifts.
// Shifts starting between 12pm and 7:59pm are designated as evening shifts.
// Shifts starting between 8pm and 3:59am are classified as night shifts.
export function getShiftType(date: Dayjs): ShiftType {
  if (date.hour() >= 4 && date.hour() < 12) {
    return 'Day';
  }
  if (date.hour() >= 12 && date.hour() < 20) {
    return 'Eve';
  }

  return 'Noc';
}
export function getTimeByShiftType(shift: string): { startTime: string; duration: string } {
  const shiftType = shift.slice(0, 3);
  const duration = parseInt(shift.slice(3, 5), 10);
  const typeToTime: Record<string, string> = {
    day: '04:00:00',
    eve: '12:00:00',
    noc: '20:00:00',
  };

  return {
    startTime: typeToTime[shiftType],
    duration: `${duration.toString().padStart(2, '0')}:00:00`,
  };
}
export function getShiftTypeByTime(startTime: string, duration: string): string {
  const startHour = parseInt(startTime.slice(0, 2), 10) ?? 0;
  const durationHour = parseInt(duration.slice(0, 2), 10) ?? 0;
  const type = startHour <= 4 ? 'day' : startHour <= 12 ? 'eve' : 'noc';
  const durationStr = durationHour < 12 ? '8h' : '12h';

  return `${type}${durationStr}`;
}
